import { EItemType } from "../enums/item-type.enum";

export class ItemSettings {
    constructor() { }
    item_type: string = EItemType.PRODUCT;
    active: boolean = true;

    barcode_scan_enabled: boolean = false;
    stock_maintenance_enabled: boolean = false;
    unit_enabled: boolean = false;
    category_enabled: boolean = false;

    party_wise_rate_enabled: boolean = false;
    description_enabled: boolean = false;
    item_wise_tax_enabled: boolean = false;

    discount_enabled: boolean = false;
    quantity_decimal_place: number = 2;

    mrp_price_enabled: boolean = false;
    mrp_price_value: number = 0;

    batch_no_enabled: boolean = false;
    batch_no_value: string = null;

    exp_date_enabled: boolean = false;
    exp_date_format: string = 'mm/yy';
    exp_date_value: string = null;

    mfg_date_enabled: boolean = false;
    mfg_date_format: string = 'dd/mm/yy';
    mfg_date_value: string = null;

    serial_no_enabled: boolean = false;
    serial_no_value: string = null;

    size_enabled: boolean = false;
    size_value: string = null;
}
