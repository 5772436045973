import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, ActionReducerMap } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GtagModule } from 'angular-gtag';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app.routing.module';

import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { SharedModule } from './shared';

import { AuthGuard } from './guards/auth/auth.guard';
import { NotAuthenticatedGuard } from './guards/not-authenticated/not-authenticated.guard';
import { GoogleAnalyticsService } from './services/google-analytics.service';

import { UriResolver } from './uri-resolver.service';
import { AppGlobalService } from './app.global.service';

import { authReducer } from './store/reducers/auth.reducers';
import { settingsReducer } from './store/reducers/settings.reducers';
import { accountReducer } from './store/reducers/accounts.reducers';
import { syncGatewayUserReducer } from './store/reducers/sync-gateway-user.reducers';
import { catalogReducer } from './store/reducers/catalog.reducers';
import { usersReducer } from './store/reducers/users.reducers';
import { permissionReducer } from './store/reducers/permissions.reducers';

import { AuthEffects } from './store/effects/auth.effects';
import { SettingsEffects } from './store/effects/settings.effects';
import { AccountsEffects } from './store/effects/accounts.effects';
import { SyncGatewayUserEffects } from './store/effects/sync-gateway-user.effects';
import { CatalogEffects } from './store/effects/catalog.effects';
import { UsersEffefcts } from './store/effects/users.effects';
import { PermissionEffects } from './store/effects/permission.effects';

const reducers: ActionReducerMap<any> = {
  settings: settingsReducer,
  auth: authReducer,
  accounts: accountReducer,
  syncGatewayUser: syncGatewayUserReducer,
  catalog: catalogReducer,
  users: usersReducer,
  permissions: permissionReducer
};

const effects: any[] = [
  SettingsEffects,
  AuthEffects,
  AccountsEffects,
  SyncGatewayUserEffects,
  CatalogEffects,
  UsersEffefcts,
  PermissionEffects
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/app/', '.json');
}

const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,


    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),

    !environment.production ?
      StoreDevtoolsModule.instrument({
        maxAge: 25, logOnly: false,
        features: { pause: false, lock: true, persist: true }
      }) : [],

    TranslateModule.forRoot(translationOptions),

    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center'
    }),

    SharedModule.forRoot(),
    GtagModule.forRoot({ trackingId: 'G-CHXLH1LM16', trackPageviews: true }),

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    // ServiceWorkerModule.register('service-worker.js', {
    //   enabled: environment.production,
    // }),
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  providers: [
    AppGlobalService,
    UriResolver, AuthGuard, NotAuthenticatedGuard, GoogleAnalyticsService,
    TranslateService, DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(["en", "bn"]);
    translate.setDefaultLang('bn');
    translate.use('bn');
  }
}
