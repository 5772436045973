export class Product {

    _id: string = null;
    _rev: string = null;

    uuid: string = null;
    name: string = null;
    code: string = null;
    business_uuid: string = null;
    branch_uuid: string = null;

    product_type: string = null;
    active: boolean = true;
    slug: string = null;
    description: string = null;

    purchase_price: number = 0
    sales_price: number = 0
    mrp_price: number = 0;
    discount: number = 0
    net_price: number = 0
    stock_count: number = 0

    image_url: string = null;
    thumbnail_url: string = null;

    unit: string = null;
    category_uuid: string = null;

    party_wise_rate: string = null;
    item_wise_tax: number = 0;

    quantity: number = 1;

    batch_no: string = null;
    exp_date: string = null;
    mfg_date: string = null;

    serial_no: string = null;
    size: string = null;

    created_at: number = Date.now();
}
