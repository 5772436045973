import { Action } from '@ngrx/store';

export enum ESettingsActions {
    SAVE_ITEM_SETTINGS = 'SAVE_ITEM_SETTINGS',
    SAVE_ITEM_SETTINGS_SUCCESS = 'SAVE_ITEM_SETTINGS_SUCCESS',
    SAVE_ITEM_SETTINGS_FAIL = 'SAVE_ITEM_SETTINGS_FAIL',
    SAVE_PARTY_SETTINGS = 'SAVE_PARTY_SETTINGS',
    SAVE_PARTY_SETTINGS_SUCCESS = 'SAVE_PARTY_SETTINGS_SUCCESS',
    SAVE_PARTY_SETTINGS_FAIL = 'SAVE_PARTY_SETTINGS_FAIL',
    SAVE_TRANSACTION_SETTINGS = 'SAVE_TRANSACTION_SETTINGS',
    SAVE_TRANSACTION_SETTINGS_SUCCESS = 'SAVE_TRANSACTION_SETTINGS_SUCCESS',
    SAVE_TRANSACTION_SETTINGS_FAIL = 'SAVE_TRANSACTION_SETTINGS_FAIL',
    SAVE_EXPORT_SETTINGS = 'SAVE_EXPORT_SETTINGS',
    SAVE_EXPORT_SETTINGS_SUCCESS = 'SAVE_EXPORT_SETTINGS_SUCCESS',
    SAVE_EXPORT_SETTINGS_FAIL = 'SAVE_EXPORT_SETTINGS_FAIL',
    LOAD_ITEM_SETTINGS = 'LOAD_ITEM_SETTINGS',
    LOAD_ITEM_SETTINGS_SUCCESS = 'LOAD_ITEM_SETTING_SUCCESS',
    LOAD_SETTINGS = 'LOAD_SETTINGS',
    LOAD_SETTINGS_SUCCESS = 'LOAD_SETTING_SUCCESS',
}


export class LoadSettingsAction implements Action {
    readonly type = ESettingsActions.LOAD_SETTINGS;
    constructor() { }
}

export class LoadSettingsSuccessAction implements Action {
    readonly type = ESettingsActions.LOAD_SETTINGS_SUCCESS;
    constructor(public payload: any) { }

}

export class LoadItemSettingsAction implements Action {
    readonly type = ESettingsActions.LOAD_ITEM_SETTINGS;
    constructor() { }
}

export class LoadItemSettingsSuccessAction implements Action {
    readonly type = ESettingsActions.LOAD_ITEM_SETTINGS_SUCCESS;
    constructor(public payload: any) { }

}

export class SaveItemSettingsAction implements Action {
    readonly type = ESettingsActions.SAVE_ITEM_SETTINGS;
    constructor(public payload: any) { 
    }
}

export class SaveItemSettingsSuccessAction implements Action {
    readonly type = ESettingsActions.SAVE_ITEM_SETTINGS_SUCCESS;
    constructor(public payload: any) { }

}

export class SaveItemSettingsFailAction implements Action {
    readonly type = ESettingsActions.SAVE_ITEM_SETTINGS_FAIL;
    constructor(public payload: any) { }
}

export class SavePartySettingsAction implements Action {
    readonly type = ESettingsActions.SAVE_PARTY_SETTINGS;
    constructor(public payload: any) { }
}

export class SavePartySettingsSuccessAction implements Action {
    readonly type = ESettingsActions.SAVE_PARTY_SETTINGS_SUCCESS;
    constructor(public payload: any) { }

}

export class SaveTransactionSettingsFailAction implements Action {
    readonly type = ESettingsActions.SAVE_TRANSACTION_SETTINGS_FAIL;
    constructor(public payload: any) { }
}

export class SaveTransactionSettingsAction implements Action {
    readonly type = ESettingsActions.SAVE_TRANSACTION_SETTINGS;
    constructor(public payload: any) { }
}

export class SaveTransactionSettingsSuccessAction implements Action {
    readonly type = ESettingsActions.SAVE_TRANSACTION_SETTINGS_SUCCESS;
    constructor(public payload: any) { }

}

export class SavePartySettingsFailAction implements Action {
    readonly type = ESettingsActions.SAVE_PARTY_SETTINGS_FAIL;
    constructor(public payload: any) { }
}

export class SaveExportSettingsAction implements Action {
  readonly type = ESettingsActions.SAVE_EXPORT_SETTINGS;
  constructor(public payload: any) { }
}

export class SaveExportSettingsSuccessAction implements Action {
  readonly type = ESettingsActions.SAVE_EXPORT_SETTINGS_SUCCESS;
  constructor(public payload: any) { }

}

export class SaveExportSettingsFailAction implements Action {
  readonly type = ESettingsActions.SAVE_EXPORT_SETTINGS_FAIL;
  constructor(public payload: any) { }
}

export type ItemSettingActions =
    SaveItemSettingsAction
    | SaveItemSettingsSuccessAction
    | SaveItemSettingsFailAction
    | LoadItemSettingsAction
    | LoadItemSettingsSuccessAction
    | LoadSettingsAction
    | LoadSettingsSuccessAction
    | SavePartySettingsAction
    | SavePartySettingsSuccessAction
    | SavePartySettingsFailAction
    | SaveTransactionSettingsFailAction
    | SaveTransactionSettingsAction
    | SaveTransactionSettingsSuccessAction
    | SaveExportSettingsAction
    | SaveExportSettingsSuccessAction
    | SaveExportSettingsFailAction;
