import { createSelector } from '@ngrx/store';

import { IAppState } from '../app.state';

export const selectAuth = (state: IAppState) => state.auth;

export const selectUser = createSelector(
    selectAuth,
    (state: any) => state?.user
);
