import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { ToastrService } from 'ngx-toastr';
import * as uuid from 'uuid';

import { of, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { ETables } from 'app/account/shared/enums';
import { Settings } from 'app/account/settings/models/settings.model';
import { ItemSettings } from 'app/account/settings/models/item-settings.model';

import {
    ESettingsActions, LoadSettingsSuccessAction,
    SaveItemSettingsAction, LoadItemSettingsSuccessAction, SaveItemSettingsFailAction,
    SavePartySettingsAction, SavePartySettingsFailAction,
    SaveTransactionSettingsAction, SaveTransactionSettingsFailAction,
    SaveExportSettingsAction, SaveExportSettingsFailAction,
} from '../actions/settings.actions';

import { UtilService } from 'app/account/shared/services';
import { PouchdbService } from 'app/services/pouchdb.service';
import { StorageService } from 'app/shared/services/storage.service';

@Injectable()
export class SettingsEffects {
    constructor(
        private actions: Actions,
        private dbService: PouchdbService,
        private storageService: StorageService,
        private utilService: UtilService,
        protected toastr: ToastrService
    ) { }


    @Effect()
    saveItemSettings$ = this.actions.pipe(
        ofType(ESettingsActions.SAVE_ITEM_SETTINGS),
        map((action: SaveItemSettingsAction) => action.payload),
        switchMap(payload => {
            return this.saveSettings(payload).pipe(
                switchMap((response) => {
                    this.toastr.success('Item settings saved');
                    return this.getSettingsById(response['_id']).pipe(
                        map(res => {
                            return res;
                        })
                    )
                }),
                switchMap((response: any) => {
                    return of(new LoadSettingsSuccessAction(response ? response : null));
                }),
                catchError((error) => {
                    console.log(error);
                    this.toastr.error(error?.message)
                    return of(new SaveItemSettingsFailAction({ error: error }));
                })
            )
        }),
    );


    @Effect()
    savePartySettings$ = this.actions.pipe(
        ofType(ESettingsActions.SAVE_PARTY_SETTINGS),
        map((action: SavePartySettingsAction) => action.payload),
        switchMap(payload => {
            return this.saveSettings(payload).pipe(
                switchMap((response) => {
                    this.toastr.success('Party settings saved');
                    return this.getSettingsById(response['_id']).pipe(
                        map(res => {
                            return res;
                        })
                    )
                }),
                switchMap((response: any) => {
                    return of(new LoadSettingsSuccessAction(response ? response : null));
                }),
                catchError((error) => {
                    console.log(error);
                    this.toastr.error(error)
                    return of(new SavePartySettingsFailAction({ error: error }));
                })
            )
        }),
    );

    @Effect()
    saveTransactionSettings$ = this.actions.pipe(
        ofType(ESettingsActions.SAVE_TRANSACTION_SETTINGS),
        map((action: SaveTransactionSettingsAction) => action.payload),
        switchMap(payload => {
            return this.saveSettings(payload).pipe(
                switchMap((response) => {
                    this.toastr.success('Transaction settings saved');
                    return this.getSettingsById(response['_id']).pipe(
                        map(res => {
                            return res;
                        })
                    )
                }),
                switchMap((response: any) => {
                    return of(new LoadSettingsSuccessAction(response ? response : null));
                }),
                catchError((error) => {
                    console.log(error);
                    this.toastr.error(error)
                    return of(new SaveTransactionSettingsFailAction({ error: error }));
                })
            )
        }),
    );


    @Effect()
    saveExportSettings$ = this.actions.pipe(
        ofType(ESettingsActions.SAVE_EXPORT_SETTINGS),
        map((action: SaveExportSettingsAction) => action.payload),
        switchMap(payload => {
            return this.saveSettings(payload).pipe(
                switchMap((response) => {
                    this.toastr.success('Export settings saved');
                    return this.getSettingsById(response['_id']).pipe(
                        map(res => {
                            return res;
                        })
                    )
                }),
                switchMap((response: any) => {
                    return of(new LoadSettingsSuccessAction(response ? response : null));
                }),
                catchError((error) => {
                    console.log(error);
                    this.toastr.error(error)
                    return of(new SaveExportSettingsFailAction({ error: error }));
                })
            )
        }),
    );


    @Effect()
    loadItemSettings$ = this.actions.pipe(
        ofType(ESettingsActions.LOAD_ITEM_SETTINGS),
        switchMap(settings => {
            return this.getSettings().pipe(
                map(response => {
                    const item = response.docs.find(e => true);
                    return !!item ? item : new ItemSettings();
                })
            );
        }),
        switchMap((response: any) => {
            return of(new LoadItemSettingsSuccessAction(response ? response : null));
        })
    );


    @Effect()
    loadSettings$ = this.actions.pipe(
        ofType(ESettingsActions.LOAD_SETTINGS),
        switchMap(() => {
            return this.getSettings().pipe(
                map(response => {
                    // Somehow, filtering and indexing for sorting by `created_at` field not working
                    // So, we do it here programmatically again.
                    const allSettings = response.docs;
                        // .filter((s: Settings) => s.created_at > 0)
                        // .sort((a: Settings, b: Settings) => a.created_at > b.created_at ? 1 : a.created_at < b.created_at ? -1 : 0);
                    const setting = allSettings.find(e => true);
                    return !!setting ? setting : new Settings();
                })
            );
        }),
        switchMap((response: any) => {
            return of(new LoadSettingsSuccessAction(response ? response : null));
        })
    );

    saveSettings(settings) {
        settings['uuid'] = settings.uuid ? settings.uuid : uuid.v4();
        settings['business_uuid'] = this.getCurrentBusinessUuid();

        let settingsData = {
            table_type: ETables.SETTINGS,
            ...settings
        }
        return from(this.dbService.put(this.utilService.buildKey(ETables.SETTINGS, settings.uuid), settingsData));
    }

    getSettings() {
        const conditions = {
            table_type: ETables.SETTINGS,
            business_uuid: this.getCurrentBusinessUuid(),
            created_at: { $exists: true }
        };

        return from(
            this.dbService.find(conditions, null, [{ created_at: 'asc' }])
        );
    }

    getSettingsById(id: string) {
        return from(this.dbService.get(id));
    }

    private getCurrentBusinessUuid() {
        return this.storageService.getCurrentBusiness();
    }
}
