
export class ExportSettings {
    constructor() { }
    show_title: boolean = true;
    show_company_name: boolean = true;
    show_company_logo: boolean = false;

    show_footer: boolean = true;
    show_export_date: boolean = false;

    show_merchant_info = false;
}
