import { Injectable } from '@angular/core';

import { ESortOrder, ETransactionType } from 'app/account/transaction/enums';
import { TransactionItem } from 'app/account/transaction/models/transaction.model';

import { UtilService } from 'app/account/shared/services';
import { TransactionService } from 'app/account/transaction/services';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private transactionService: TransactionService, private utilService: UtilService) { }

  public getPaymentInOut(params: any, transaction_type: string) {
    const { party_uuid, start_date, end_date } = params;
    let findConditions = {
      type: transaction_type,
      ...this.buildDateConditions(start_date, end_date)
    };

    if (party_uuid) {
      findConditions['party_uuid'] = party_uuid
    }

    return this.transactionService.getTransactions(findConditions, ESortOrder.ASC);
  }

  public getTransactionsByTypes(params: any) {
    const { party_uuid, transaction_types, start_date, end_date } = params;

    let typeCondition = {};
    if (Array.isArray(transaction_types) && transaction_types.length > 1) {
      typeCondition = { '$in': transaction_types };
    } else {
      typeCondition = transaction_types;
    }

    let findConditions = {
      type: typeCondition,
      ...this.buildDateConditions(start_date, end_date)
    };

    if (party_uuid) {
      findConditions['party_uuid'] = party_uuid
    }

    return this.transactionService.getTransactions(findConditions, ESortOrder.ASC);
  }

  calculateQuantity(transactions: TransactionItem[]) {
    let sale = 0;
    let purchase = 0;
    transactions.map((transactionItem) => {
      if (transactionItem?.transaction_type === ETransactionType.SALES) {
        sale = sale + transactionItem?.quantity;
      } else if (transactionItem?.transaction_type === ETransactionType.SALES_RETURN) {
        sale = sale - transactionItem?.quantity;
      } else if (transactionItem?.transaction_type === ETransactionType.PURCHASE) {
        purchase = purchase + transactionItem?.quantity;
      } else if (transactionItem?.transaction_type === ETransactionType.PURCHASE_RETURN) {
        purchase = purchase - transactionItem?.quantity;
      }
    });
    return { sale, purchase }
  }

  // TODO - remove this method, and use the one from `utilService`
  public buildDateConditions(start_date?: Date, end_date?: Date) {
    return this.utilService.buildDateConditions(start_date, end_date);
  }

  public buildLessThanDateCondition(start_date?: Date) {
    const transaction_date = {};

    if (start_date) {
      transaction_date['$lt'] = start_date.getTime();
    }

    if (start_date) {
      return { transaction_date };
    }

    return {};
  }
}
