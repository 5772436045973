import { createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';

export const selectPermission = (state: IAppState) => state?.permissions;

export const selectCurrentRole = createSelector(
    selectPermission,
    (state: any) => state?.permissions?.role
);

export const selectCurrentPermissions = createSelector(
    selectPermission,
    (state: any) => state?.permissions?.permissions
);
