import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { StorageService } from 'app/shared/services/storage.service';

const jwtHelper = new JwtHelperService();

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {

    private rawToken: string = null;

    constructor(private router: Router, private storageService: StorageService) { }

    private isAuthenticated(): boolean {
        this.rawToken = this.storageService.getToken();
        if (this.rawToken) {
            const isExpired = jwtHelper.isTokenExpired(this.rawToken);
            return !isExpired;
        }
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        if (this.isAuthenticated()) {
            this.router.navigateByUrl("/dashboard");
            return false;
        } else {
            return true;
        }
    }
}
