import { Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/app.state';

import { BasePouchDbList } from '../base-pouchdb-list';

export class BasePouchDbPagination extends BasePouchDbList {
  protected itemsPerPage: number = 20;
  protected currentPage: number = 1;
  protected offset: number = 0;
  protected skip: number = 0;

  protected options = {
    query: this.query,
    fields: this.fields,
    filter: this.filter,
    include_docs: true,
    limit: this.itemsPerPage,
    skip: this.skip,
  };

  constructor(protected injector: Injector, public store: Store<IAppState>) {
    super(injector, store);
  }

  protected setOptions() {
    this.options = {
      query: this.query,
      fields: this.fields,
      filter: this.filter,
      include_docs: true,
      limit: this.itemsPerPage,
      skip: this.skip,
    };
  }

  public pageChanged(e) {
    this.currentPage = e.page;

    this.offset = 0;
    this.offset = (this.currentPage - 1) * this.itemsPerPage;

    let page = Math.ceil(((this.offset - 1) / this.itemsPerPage));
    this.skip = page * this.itemsPerPage;

    this.mapData().subscribe(data => {
      this.results = data;
    });
  }
}
