import { EPermissionActions, PermissionActions } from '../actions/permission.actions';
import { RolePermission  } from 'app/account/utils/permission/role-permission.config';

export const initialState = {
    //set initial state
    permissions: RolePermission
};
export function permissionReducer(state = initialState, action: PermissionActions) {
    switch (action.type) {
        case EPermissionActions.PERMISSION_SET: {
            return {
                ...state,
                permissions: action.payload,
             };
        }
        case EPermissionActions.PERMISSION_SET_SUCCESS: {
            return {
                ...state,
            };
        }

        default:
            return state;
    }
}
