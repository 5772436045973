import { Action } from '@ngrx/store';

export enum EUsersActions {
    LOAD_USERS = '[Users Component] Load Users',
    LOAD_USERS_SUCCESS = '[Users Effect] Load Users Success',
    ADD_USER = '[Users Component] Add User',
    ADD_USER_SUCCESS = '[Users Effect] Add User Success',
    DELETE_USER = '[Users Component] Delete User',
    DELETE_USER_SUCCESS = '[Users Component] Delete User Success',
    USERS_FALSE_ACTION = '[Users False Action] Users False Action',
}

export class LoadUsersAction implements Action {
    readonly type = EUsersActions.LOAD_USERS;
}

export class LoadUsersSuccessAction implements Action {
    readonly type = EUsersActions.LOAD_USERS_SUCCESS;
    constructor(public payload: any) { }
}

export class AddUserAction implements Action {
    readonly type = EUsersActions.ADD_USER;
    constructor(public payload: any) { }
}

export class AddUserSuccessAction implements Action {
    readonly type = EUsersActions.ADD_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteUserAction implements Action {
    readonly type = EUsersActions.DELETE_USER;
    constructor(public payload: any) { }
}

export class DeleteUserSuccessAction implements Action {
    readonly type = EUsersActions.DELETE_USER_SUCCESS;
    constructor(public payload: any) { }
}

export class UsersFalseAction implements Action {
    readonly type = EUsersActions.USERS_FALSE_ACTION;
}

export type UsersActions = LoadUsersAction
    | LoadUsersSuccessAction
    | AddUserAction
    | AddUserSuccessAction
    | DeleteUserAction
    | DeleteUserSuccessAction
    | UsersFalseAction
