import { Action } from '@ngrx/store';
import { PartyBalance } from 'app/account/party/models';

export enum ECatalogActions {
    CATALOG_LOAD = "CATALOG_LOAD",
    CATALOG_LOAD_SUCCESS = "CATALOG_LOAD_SUCCESS",
    CATALOG_PRODUCT_ADD = "CATALOG_PRODUCT_ADD",
    CATALOG_PRODUCT_UPDATE = "CATALOG_PRODUCT_UPDATE",
    CATALOG_PRODUCT_DELETE = "CATALOG_PRODUCT_DELETE",
    CATALOG_PRODUCT_UPDATE_BY_TRANSACTION = "CATALOG_PRODUCT_UPDATE_BY_TRANSACTION",
    CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE = "CATALOG_PRODUCT_DELETE_BY_TRANSACTION_DELETE",
    CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE_SUCCESS = "CATALOG_PRODUCT_DELETE_BY_TRANSACTION_DELETE_SUCCESS",
    CATALOG_PRODUCT_UPDATE_SUCCESS = "CATALOG_PRODUCT_UPDATE_SUCCESS",
    CATALOG_PARTY_ADD = "CATALOG_PARTY_ADD",
    CATALOG_PARTY_UPDATE = "CATALOG_PARTY_UPDATE",
    CATALOG_PARTY_UPDATE_BY_TRANSACTION = "CATALOG_PARTY_UPDATE_BY_TRANSACTION",
    CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE = "CATALOG_PARTY_DELETE_BY_TRANSACTION_DELETE",
    CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE_SUCCESS = "CATALOG_PARTY_DELETE_BY_TRANSACTION_DELETE_SUCCESS",
    CATALOG_PARTY_UPDATE_SUCCESS = "CATALOG_PARTY_UPDATE_SUCCESS",
}

export class LoadCatalogAction implements Action {
    readonly type = ECatalogActions.CATALOG_LOAD;
    constructor() { }
}

export class LoadCatalogSuccessAction implements Action {
    readonly type = ECatalogActions.CATALOG_LOAD_SUCCESS;
    constructor(public catalog: { products: [], parties: [] }) { }
}

export class AddCatalogProductAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_ADD;
    constructor(public payload: any) { }
}

export class UpdateCatalogProductAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_UPDATE;
    constructor(public payload: any) { }
}

export class DeleteCatalogProductAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_DELETE;
    constructor(public payload: any) { }
}

export class UpdateCatalogProductByTransactionAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION;
    constructor(public payload: any) { }
}

export class UpdateCatalogProductByTransactionDeleteAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE;
    constructor(public payload: any) { }
}

export class UpdateCatalogProductByTransactionDeleteSuccessAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateCatalogProductSuccessAction implements Action {
    readonly type = ECatalogActions.CATALOG_PRODUCT_UPDATE_SUCCESS;
    constructor(public payload: any) { }
}

export class AddCatalogPartyAction implements Action {
    readonly type = ECatalogActions.CATALOG_PARTY_ADD;
    constructor(public party: PartyBalance) { }
}

export class UpdateCatalogPartyAction implements Action {
    readonly type = ECatalogActions.CATALOG_PARTY_UPDATE;
    constructor(public payload: any) { }
}

export class UpdateCatalogPartyByTransactionAction implements Action {
    readonly type = ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION;
    constructor(public payload: any) { }
}

export class UpdateCatalogPartyByTransactionDeleteAction implements Action {
    readonly type = ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE;
    constructor(public payload: any) { }
}

export class UpdateCatalogPartyByTransactionDeleteSuccessAction implements Action {
    readonly type = ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE_SUCCESS;
    constructor(public payload: any) { }
}

export class UpdateCatalogPartySuccessAction implements Action {
    readonly type = ECatalogActions.CATALOG_PARTY_UPDATE_SUCCESS;
    constructor(public payload: any) { }
}

export type CatalogActions = LoadCatalogAction
    | LoadCatalogSuccessAction
    | AddCatalogProductAction
    | UpdateCatalogProductAction
    | DeleteCatalogProductAction
    | UpdateCatalogProductByTransactionAction
    | UpdateCatalogProductByTransactionDeleteAction
    | UpdateCatalogProductByTransactionDeleteSuccessAction
    | UpdateCatalogProductSuccessAction
    | UpdateCatalogPartyAction
    | UpdateCatalogPartyByTransactionAction
    | UpdateCatalogPartyByTransactionDeleteAction
    | UpdateCatalogPartyByTransactionDeleteSuccessAction
    | UpdateCatalogPartySuccessAction;
