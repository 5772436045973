export class RolePermission {
    role: Roles;
    permissions: Permissions;

    constructor(role, permissions){
        this.role = role;
        this.permissions = permissions;
    }
}

export enum Roles {
   OWNER = "owner",
   EDITOR = "editor",
   VIEWER = "viewer"
}

export class Permission {
   can_view: boolean = false;
   can_add: boolean = false;
   can_edit: boolean = false;
   can_delete: boolean = false;

   constructor({can_view, can_add, can_edit, can_delete}) {
        this.can_view = can_view;
        this.can_add = can_add;
        this.can_edit = can_edit;
        this.can_delete = can_delete;
   };
}

export class Permissions {
    category: Permission;
    product: Permission;
    party: Permission;
    party_group: Permission;
    purchase: Permission;
    purchase_return: Permission;
    payment_out: Permission;
    sales: Permission;
    sales_return: Permission;
    payment_in: Permission;
    expense: Permission;
    expense_category: Permission;
    report: Permission;
    setting: Permission;
    utility: Permission;
    transactions: Permission;
    user: Permission;
    subscription: Permission;
    sale_report: Permission;
    purchase_report: Permission;
    profit_loss_report: Permission;
    sale_purchase_party_report: Permission;
    item_report_by_party: Permission;
    party_report_by_item: Permission;
    stock_summary_report: Permission;
    item_detail_report: Permission;
    all_party_report: Permission;
    party_wise_profit_loss_report: Permission;
    party_statement_report: Permission;
    cashflow_report: Permission;
    daybook_report: Permission;
    balance_sheet_report: Permission;

    constructor({
        category,
        product,
        party,
        party_group,
        purchase,
        purchase_return,
        payment_out,
        sales,
        sales_return,
        payment_in,
        expense,
        expense_category,
        report,
        setting,
        utility,
        transactions,
        user,
        subscription,
        sale_report,
        purchase_report,
        profit_loss_report,
        sale_purchase_party_report,
        item_report_by_party,
        party_report_by_item,
        stock_summary_report,
        item_detail_report,
        all_party_report,
        party_wise_profit_loss_report,
        party_statement_report,
        cashflow_report,
        daybook_report,
        balance_sheet_report
    }) {
        this.category = category;
        this.product = product;
        this.party = party;
        this.party_group = party_group;
        this.purchase = purchase;
        this.purchase_return = purchase_return;
        this.payment_out = payment_out;
        this.sales = sales;
        this.sales_return = sales_return;
        this.payment_in = payment_in;
        this.expense = expense;
        this.expense_category = expense_category;
        this.report = report;
        this.setting = setting;
        this.utility = utility;
        this.transactions = transactions;
        this.user = user;
        this.subscription = subscription;
        this.sale_report = sale_report;
        this.purchase_report = purchase_report;
        this.profit_loss_report = profit_loss_report;
        this.sale_purchase_party_report = sale_purchase_party_report;
        this.item_report_by_party = item_report_by_party;
        this.party_report_by_item = party_report_by_item;
        this.stock_summary_report = stock_summary_report;
        this.item_detail_report = item_detail_report;
        this.all_party_report = all_party_report;
        this.party_wise_profit_loss_report = party_wise_profit_loss_report;
        this.party_statement_report = party_statement_report;
        this.cashflow_report = cashflow_report;
        this.daybook_report = daybook_report;
        this.balance_sheet_report = balance_sheet_report;
    }
}

export class RolePermissionConfig {
    static ownerPermissions: RolePermission = new RolePermission(
        Roles.OWNER,
        new Permissions(
          {
            category: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            product: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            party: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            party_group: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            purchase: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            purchase_return: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            payment_out: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            sales: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            sales_return: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            payment_in: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            expense: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            expense_category: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            report: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            setting: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            utility: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            transactions: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            user: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            subscription: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
            sale_report: new Permission({can_view: true, can_add: true, can_edit: false, can_delete: false}),
            purchase_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            profit_loss_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            sale_purchase_party_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            item_report_by_party: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            party_report_by_item: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            stock_summary_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            item_detail_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            all_party_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            party_wise_profit_loss_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            party_statement_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            cashflow_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            daybook_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
            balance_sheet_report: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true})
          }
        ),
    );

    static editorPermissions: RolePermission = new RolePermission(
      Roles.EDITOR,
      new Permissions(
        {
          category: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          product: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          party: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          party_group: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          purchase: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          purchase_return: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          payment_out: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          sales: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          sales_return: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          payment_in: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          expense: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          expense_category: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          setting: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          utility: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          transactions: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          user: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false}),
          subscription: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          sale_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          purchase_report: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: false}),
          profit_loss_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          sale_purchase_party_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          item_report_by_party: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          party_report_by_item: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          stock_summary_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          item_detail_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          all_party_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          party_wise_profit_loss_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          party_statement_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          cashflow_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          daybook_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
          balance_sheet_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false})
        }
      ),
    );

    static viewerPermissions: RolePermission = new RolePermission(
        Roles.VIEWER,
        new Permissions(
            {
              category: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              product: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              party: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              party_group: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              purchase: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              purchase_return: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              payment_out: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              sales: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              sales_return: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              payment_in: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              expense: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              expense_category: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              setting: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              utility: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false}),
              transactions: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              user: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false}),
              subscription: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              sale_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              purchase_report: new Permission({can_view: true, can_add: true, can_edit: true, can_delete: true}),
              profit_loss_report: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false}),
              sale_purchase_party_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              item_report_by_party: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              party_report_by_item: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              stock_summary_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              item_detail_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              all_party_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              party_wise_profit_loss_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              party_statement_report: new Permission({can_view: true, can_add: false, can_edit: false, can_delete: false}),
              cashflow_report: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false}),
              daybook_report: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false}),
              balance_sheet_report: new Permission({can_view: false, can_add: false, can_edit: false, can_delete: false})
            }
      ),
    )
  }
