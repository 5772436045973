
export class TransactionSettings {
    constructor() { }

    invoice_no_enabled: boolean = true;
    cash_sale_by_default: boolean = false;
    show_parties_billing_name: boolean = false;
    customer_PO_details: boolean = false;

    inclusive_tax_on_rate: boolean = false;
    show_item_purchase_price: boolean = false;
    show_item_sales_price: boolean = false;
    show_free_item_quantity: boolean = false;

    transaction_wise_tax: boolean = false;
    transaction_wise_discount: boolean = false;
    round_total: boolean = false;

    show_invoice_preview: boolean = true;
    enable_passcode_on_transaction_delete: boolean = false;
    enable_payment_discount: boolean = false;
}
