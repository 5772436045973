import { Action } from '@ngrx/store';

export enum EPermissionActions {
    PERMISSION_SET = "PERMISSION_SET",
    PERMISSION_SET_SUCCESS = "PERMISSION_SET_SUCCESS",
}

export class PermissionSetAction implements Action {
    readonly type = EPermissionActions.PERMISSION_SET;
    constructor(public payload: any) { }
}

export class PermissionSetSuccessAction implements Action {
    readonly type = EPermissionActions.PERMISSION_SET_SUCCESS;
    constructor() { }
}

export type PermissionActions = PermissionSetAction | PermissionSetSuccessAction;
