import { Injectable } from "@angular/core";
import { SyncGatewayUser } from "../models/sync-gateway-user.model";


@Injectable()
export class StorageService {
    private languages: any[] = ['en', 'bn'];

    getUser(): any {
        const user = window.localStorage['authuser'];
        if (user) {
            return JSON.parse(window.localStorage['authuser']);
        } else {
            return null
        }
    }

    saveUser(user: any) {
        window.localStorage['authuser'] = JSON.stringify(user);
    }

    removeUser() {
        return localStorage.removeItem('authuser');
    }

    getToken(): string {
        return window.localStorage['token'];
    }

    saveToken(token: String) {
        window.localStorage['token'] = `Bearer ${token}`;
    }

    removeToken() {
        return localStorage.removeItem('token');
    }

    getRefreshToken(): string {
        return window.localStorage['refresh'];
    }

    saveRefreshToken(token: String) {
        window.localStorage['refresh'] = token;
    }

    removeRefreshToken() {
        return localStorage.removeItem('refresh');
    }

    getACL(): any {
        if (window.localStorage.getItem('acl')) {
            return JSON.parse(window.localStorage.getItem('acl'));
        }
        return undefined;
    }

    saveACL(acl: any) {
        window.localStorage.setItem('acl', JSON.stringify(acl));
    }

    getNavMenu(): any {
        const menu = window.localStorage.getItem('navMenu');
        if (menu) {
            return JSON.parse(menu);
        }
        return undefined;
    }

    saveNavMenu(navMenu: any) {
        if (navMenu) {
            window.localStorage.setItem('navMenu', JSON.stringify(navMenu));
        }
    }

    getLanguageCode(): string {
        const index = this.languages.indexOf(window.localStorage['lang']);
        return index >= 0 ? window.localStorage['lang'] : 'en';
    }

    setLanguageCode(lanCode: string) {
        const index = this.languages.indexOf(lanCode);

        if (index >= 0) {
            window.localStorage['lang'] = lanCode;
            return;
        }
        window.localStorage['lang'] = 'en';
    }

    setSyncGatewayUser(syncUser: SyncGatewayUser) {
        if (syncUser) {
            window.localStorage.setItem('syncuser', JSON.stringify(syncUser));
        }
    }

    getSyncGatewayUser() {
        const syncUser = window.localStorage.getItem('syncuser');
        if (syncUser) {
            return JSON.parse(syncUser);
        }
        return undefined;
    }

    getCurrentBusiness() {
        return localStorage.getItem('curbiz');
    }

    saveCurrentBusiness(bizUuid) {
        window.localStorage['curbiz'] = bizUuid;
    }

    removeCurrentBusiness() {
        return localStorage.removeItem('curbiz');
    }

    getCurrentRole() {
        return localStorage.getItem('currole');
    }

    saveCurrentRole(currentRole: String) {
        window.localStorage['currole'] = currentRole;
    }

    removeCurrentRole() {
        return localStorage.removeItem('currole');
    }

    getSharedBusinessList(): any {
        const sharedBusinesses = window.localStorage['sharedbiz'];
        if (sharedBusinesses) {
            return JSON.parse(window.localStorage['sharedbiz']);
        } else {
            return null
        }
    }

    saveSharedBusinessList(sharedBusinesses: any) {
        window.localStorage['sharedbiz'] = JSON.stringify(sharedBusinesses);
    }

    getCurrentUserUuid() {
        return localStorage.getItem('curuid');
    }

    saveCurrentUserUuid(userUuid: string) {
        window.localStorage['curuid'] = userUuid;
    }

    removeCurrentUserUuid() {
        return localStorage.removeItem('curuid');
    }

    getCurrentBranchUuid(): any {
        return localStorage.getItem('branch_uuid');
    }

    setCurrentBranchUuid(branch_uuid: string) {
        window.localStorage['branch_uuid'] = branch_uuid;
    }

    destroyAll() {
        const language = this.getLanguageCode();
        const refreshToken = this.getRefreshToken();
        window.localStorage.clear();
        if (language) {
            this.setLanguageCode(language);
        }
        if (refreshToken) {
            this.saveRefreshToken(refreshToken);
        }
    }

    savePhoneNo(phone_number: String) {
        window.localStorage['phone_number'] = phone_number;
    }

    removePhoneNo() {
        return localStorage.removeItem('phone_number');
    }

    getPhoneNo(): string {
        return window.localStorage['phone_number'];
    }
}
