import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { tap, catchError, switchMap } from "rxjs/operators";

import { IAppState } from 'app/store/app.state';

import { selectAccounts } from 'app/store/selectors/accounts.selectors';
import { BaseService } from 'app/shared/services/base.service';

import { environment } from 'environments/environment';

@Injectable()
export class SyncGatewayUserService extends BaseService {
    private urlPath: string = 'sync-users';
    private account$: Observable<any>;

    constructor(injector: Injector,
        private store: Store<IAppState>,) {
        super(injector);
        this.account$ = this.store.select(selectAccounts);
    }

    public create(name: string) {
        const user = this.storageService.getUser();

        return this.account$.pipe(
            switchMap(account => {
                return this.http.post(`${environment.syncUserApiPath}${this.urlPath}`, {
                    name: name,
                    merchant_uuid: user.merchant_uuid,
                    admin_channels: account?.businesses?.map(b => b.uuid) || []
                }, { headers: this.headers }).pipe(
                    tap(this.successFunction),
                    catchError(this.errorFunction),
                );
            })
        );
    }

    public getUser(name: string) {
        return this.http.get(`${environment.syncUserApiPath}${this.urlPath}/${name}`, {
            headers: this.headers
        }).pipe(
            tap(this.successFunction),
            catchError(this.errorFunction),
        );
    }
}