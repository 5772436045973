import { ExportSettings } from "./export-settings.model";
import { ItemSettings } from "./item-settings.model";
import { PartySettings } from "./party-settings.model";
import { TransactionSettings } from "./transaction-settings.model"

export class Settings {

    constructor() { }

    _id: string = null;
    _rev: string = null;

    uuid: string = null;
    business_uuid: string = null;

    item_settings: ItemSettings = new ItemSettings();
    party_settings: PartySettings = new PartySettings();
    transaction_settings: TransactionSettings = new TransactionSettings();
    export_settings: ExportSettings = new ExportSettings();

    created_at: number = Date.now();
    created_by: string;
}
