import { EPartyType } from "../enums";

export class PartyBalance {
    uuid: string = null;
    name: string = null;
    party_type: string = EPartyType.CUSTOMER;

    phone: string = null;
    email: string = null;

    address: string = null;
    shipping_address: string = null;
    tin: string = null;

    current_balance_date: number;
    current_balance: number = 0
    can_delete: boolean;

    created_at: number;

    balance: number = 0;
    total_amount?: number;
}
