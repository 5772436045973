import { Injectable, Injector } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { IAppState } from '../app.state';

import {
    EAccountActions,
    AccountInfoFetchSuccessAction,
    AccountInfoFetchFailAction
} from '../actions/accounts.actions';

import { SyncUserLoadAction } from '../actions/sync-gateway-user.actions';

import { StorageService } from 'app/shared/services/storage.service';
import { BaseService } from 'app/shared/services/base.service';
import { RolePermissionConfig, Roles } from 'app/account/utils/permission/role-permission.config';
import { PermissionSetAction } from '../actions/permission.actions';

@Injectable()
export class AccountsEffects extends BaseService {

    private user = null;
    constructor(
        injector: Injector,
        public storageService: StorageService,
        private store: Store<IAppState>,
        private action$: Actions) {
        super(injector);
        this.user = this.storageService.getUser();
    }

    @Effect()
    loadAccountInfo$ = this.action$.pipe(
        ofType(EAccountActions.ACCOUNT_INFO_FETCH),
        switchMap(() => {
            return this.getAccountInfo().pipe(
                map(response => {
                    return response.data;
                }),
                map(response => {
                    let sharedBusinesses = [];

                    response?.sharedBusinesses.forEach(sharedBusiness => {
                        sharedBusinesses.push(sharedBusiness.business);
                    });

                    this.storageService.saveSharedBusinessList(response?.sharedBusinesses);

                    response.businesses = [...response.businesses, ...sharedBusinesses];
                    return response;
                }),
                switchMap((response: any) => {
                    if (response && response.businesses.length) {
                        let bizUuid = null;
                        let userUuid = null;
                        let currentRole = null;

                        bizUuid = this.storageService.getCurrentBusiness();
                        userUuid = this.storageService.getCurrentUserUuid();
                        currentRole = this.storageService.getCurrentRole();

                        const existingBusiness = response.businesses.find(x => x.uuid == bizUuid);
                        response = { ...response, currentBusiness: existingBusiness ? existingBusiness : response.businesses.length ? response.businesses[0] : null };

                        let permission = RolePermissionConfig.ownerPermissions;

                        if (currentRole != null) {
                            if (currentRole.toLowerCase() == Roles.EDITOR.toLowerCase()) {
                                permission = RolePermissionConfig.editorPermissions;
                            } else if (currentRole.toLowerCase() == Roles.VIEWER.toLowerCase()) {
                                permission = RolePermissionConfig.viewerPermissions;
                            }
                        } else {
                            // if current role is null, then set default role to owner
                            this.storageService.saveCurrentRole(Roles.OWNER);
                        }

                        this.store.dispatch(new PermissionSetAction(permission));
                    }

                    return of(new AccountInfoFetchSuccessAction(response ? response : null));
                }),
                catchError((error) => {
                    this.toastr.error(error)
                    return of(new AccountInfoFetchFailAction({ error: error }));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    accountFetchSuccess$ = this.action$.pipe(
        ofType(EAccountActions.ACCOUNT_INFO_GET_SUCCESS),
        map((action: AccountInfoFetchSuccessAction) => action.payload),
        tap((data) => {
            this.store.dispatch(new SyncUserLoadAction());
        })
    );

    private getAccountInfo() {
        this.user = this.storageService.getUser();
        return this.get(`account/${this.user.merchant_uuid}/businesses`);
    }
}
