import { createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';

export const selectAccounts = (state: IAppState) => state.accounts?.accountInfo;

export const selectCurrentBusiness = createSelector(
    selectAccounts,
    (state: any) => state?.currentBusiness
);

export const selectMerchant = createSelector(
    selectAccounts,
    (state: any) => state?.merchant
);

export const selectSubscription = createSelector(
    selectCurrentBusiness,
    (state: any) => state?.subscription
);

export const selectCurrentPackage = createSelector(
    selectSubscription,
    (state: any) => state?.package
);
