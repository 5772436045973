import { Injectable, Injector } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import * as uuid from 'uuid';

import { Observable, from, of, forkJoin } from 'rxjs';
import { switchMap, map, concatMap, mergeMap, tap, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/app.state';
import { selectCurrentBusiness } from 'app/store/selectors/accounts.selectors';

import { UpdateCatalogProductByTransactionAction, UpdateCatalogPartyByTransactionAction, UpdateCatalogProductByTransactionDeleteAction, UpdateCatalogPartyByTransactionDeleteAction } from 'app/store/actions/catalog.actions';

import { ETables } from 'app/account/shared/enums';
import { ESortOrder, ETransactionType } from '../../enums';

import { BasePouchDbList } from 'app/core/base-pouchdb-list';
import { UtilService } from 'app/account/shared/services';
import { Transaction, TransactionItem, TransactionSaveResponse, UpdatedTransaction, PrevTransaction, DeletedTransaction } from 'app/account/transaction/models';
import { TransactionItemService } from '..';
import { StorageService } from 'app/shared/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class TransactionService extends BasePouchDbList {
    private currentBusiness: any;
    private businessUuid: string = null;
    private branchUuid: string = null;

    private currentBusiness$: Observable<any>;

    constructor(
        injector: Injector,
        private utilService: UtilService,
        public store: Store<IAppState>,
        protected toastr: ToastrService,
        private transactionItemService: TransactionItemService,
        private storageService: StorageService
    ) {
        super(injector, store);
        this.currentBusiness$ = this.store.select(selectCurrentBusiness);
    }

    public addTransaction(transaction: Transaction) {
        return this.saveTransaction(transaction).pipe(
            tap((response : TransactionSaveResponse) => {
                let savedTransaction = response.transaction;
                savedTransaction.items = response.items;
                this.dispatchCatalogUpdateEvents(savedTransaction);
            })
        );
    }

    private saveTransaction(transaction: Transaction): Observable<TransactionSaveResponse> {
        return this.currentBusiness$.pipe(
            first(),
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;
                this.branchUuid = this.storageService.getCurrentBranchUuid();

                // TODO - refactor uuid generation, because current flow will prepend table key multiple times
                transaction.uuid = transaction.uuid ? transaction.uuid : uuid.v4();
                const transactionItems: TransactionItem[] = JSON.parse(JSON.stringify(transaction.items));
                transaction.items = transaction.items.map(item => item.item_uuid);

                // TODO - we should fetch business uuid from redux store
                transaction.business_uuid = this.businessUuid;
                transaction.branch_uuid = this.branchUuid;

                const transactionData = {
                    ...transaction,
                    table_type: ETables.TRANSACTION,
                };

                return from(this.dbService.put(this.utilService.buildKey(ETables.TRANSACTION, transaction.uuid), transactionData)).pipe(
                    switchMap((newTransaction: Transaction) => {
                        if (transactionItems.length) {
                            return of(transactionItems).pipe(
                                mergeMap(items => {
                                    const saveObservables = items.map(item => {
                                        const builtItem = this._buildTransactionItem(item, transactionData);
                                        return <Observable<any>>this.transactionItemService.saveTransactionItem(builtItem);
                                    });
                                    return forkJoin(saveObservables);
                                }),
                                map(items => {
                                    const response: TransactionSaveResponse = { transaction: newTransaction, items: items };
                                    return response;
                                }),
                            );
                        } else {
                            const response: TransactionSaveResponse = { transaction: newTransaction, items: [] };
                            return of(response);
                        }
                    })
                );
            })
        );
    }

    updateTransaction(prevTransaction: PrevTransaction, updatedTransaction: UpdatedTransaction) {
        return this.saveForUpdate(prevTransaction, updatedTransaction).pipe(
            tap((response : TransactionSaveResponse) => {
                let editedTransaction = response.transaction;
                editedTransaction.items = response.items;
                this.dispatchCatalogUpdateEvents(editedTransaction, prevTransaction);
            })
        );
    }

    private saveForUpdate(prevTransaction: PrevTransaction, updatedTransaction: UpdatedTransaction): Observable<any> {
        if (prevTransaction.items.length) {
            return of(prevTransaction.items).pipe(
                mergeMap(items => {
                    const deleteObservables = items.map(item => {
                        return <Observable<any>>this.transactionItemService.deleteTransactionItem(item);
                    });
                    return forkJoin(deleteObservables);
                }),
                mergeMap(items => {
                    return this.saveTransaction(updatedTransaction);
                }),
            );
        };
        return this.saveTransaction(updatedTransaction);
    }

    private dispatchCatalogUpdateEvents(updatedTransaction: UpdatedTransaction, prevTransaction: PrevTransaction = null) {
        if (updatedTransaction.type != ETransactionType.PAYMENT_IN && updatedTransaction.type != ETransactionType.PAYMENT_OUT) {
            this.store.dispatch(new UpdateCatalogProductByTransactionAction(
                {
                    updatedTransaction: updatedTransaction,
                    prevTransaction: prevTransaction
                })
            );
        }
        this.store.dispatch(new UpdateCatalogPartyByTransactionAction(
            {
                updatedTransaction: updatedTransaction,
                prevTransaction: prevTransaction
            })
        );
    }

    private dispatchCatalogDeleteEvents(deletedTransaction: DeletedTransaction) {
        if (deletedTransaction.type != ETransactionType.PAYMENT_IN && deletedTransaction.type != ETransactionType.PAYMENT_OUT) {
            this.store.dispatch(new UpdateCatalogProductByTransactionDeleteAction(
                {
                    deletedTransaction: deletedTransaction,
                })
            );
        }
        this.store.dispatch(new UpdateCatalogPartyByTransactionDeleteAction(
            {
                deletedTransaction: deletedTransaction
            })
        );
    }

    getPartyTransactions(conditionParams: any, party: any) {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;

                const conditions = {
                    table_type: ETables.TRANSACTION,
                    business_uuid: this.businessUuid,
                    party_uuid: party.uuid,
                    ...conditionParams
                };

                return from(
                    this.dbService.find(conditions, null, [{ transaction_date: ESortOrder.ASC }])
                ).pipe(
                    map(result => result.docs)
                );
            })
        );
    }

    getTransactions(conditionParams: any, sortOrder = ESortOrder.DESC) {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;

                const conditions = {
                    table_type: ETables.TRANSACTION,
                    business_uuid: this.businessUuid,
                    ...conditionParams
                };

                return from(
                    this.dbService.find(conditions, null, [{ transaction_date: sortOrder }])
                ).pipe(
                    map(result => result.docs)
                );
            })
        );
    }

    /**
     * @returns transaction with items
     * @param uuid transaction uuid
     */
    getTransactionById(uuid: string) {
        return this.currentBusiness$.pipe(
            switchMap((currentBusiness: any) => {
                this.currentBusiness = currentBusiness;
                this.businessUuid = this.currentBusiness?.uuid;

                const conditions = {
                    uuid: uuid,
                    table_type: ETables.TRANSACTION,
                    business_uuid: this.businessUuid,
                };
                return from(
                    this.dbService.find(conditions)
                ).pipe(
                    map(result => {
                        return result.docs.find(doc => true) || null;
                    }),
                    concatMap((transaction: Transaction) => {
                        const itemConditions = {
                            table_type: ETables.TRANSACTION_ITEM,
                            business_uuid: this.businessUuid,
                            transaction_uuid: transaction.uuid,
                            transaction_type: transaction.type
                        };
                        return from(
                            this.dbService.find(itemConditions)
                        ).pipe(
                            map(result => result.docs),
                            map(items => {
                                transaction.items = items;
                                return transaction;
                            }),
                        )
                    })
                );
            })
        );
    }

    deleteTransaction(doc: any) {
        let deletedTransaction = doc;
        return from(this.dbService.remove(doc)).pipe(
            switchMap(res => {
                const conditions = {
                    table_type: ETables.TRANSACTION_ITEM,
                    transaction_uuid: doc.uuid
                };
                return from(
                    this.dbService.find(conditions)
                ).pipe(
                    map(result => result.docs),
                    map(items => {
                        deletedTransaction.items = items;
                        return items;
                    }),
                );
            }),
            switchMap(items => {
                if (items.length) {
                    return from(items).pipe(
                        concatMap(item => <Observable<any>>this.transactionItemService.deleteTransactionItem(item)),
                    );
                } else {
                    return of(items);
                }
            }),
            switchMap(() => {
                return of(deletedTransaction);
            }),
            tap((deletedTransaction) => {
                this.dispatchCatalogDeleteEvents(deletedTransaction);
            }),
        );
    }

    calculateQuantityAmount(items: any[] = [], type: string) {
        let total_amount = 0;
        let quantity = 0;
        let total_tax = 0;

        quantity = items.reduce((acc, item) => {
            return acc + item.quantity;
        }, 0);

        total_tax = items.reduce((acc, item) => {
            return acc + item.total_tax;
        }, 0);

        if (type === ETransactionType.PURCHASE || type === ETransactionType.PURCHASE_RETURN) {
            total_amount = items.reduce((acc, item) => {
                return acc + (item.purchase_price * item.quantity);
            }, 0);
        }
        if (type === ETransactionType.SALES || type === ETransactionType.SALES_RETURN) {
            total_amount = items.reduce((acc, item) => {
                return acc + (item.sales_price * item.quantity);
            }, 0);
        }

        return { quantity, total_amount, total_tax }
    }

    private _buildTransactionItem(item: TransactionItem, transactionInfo) {
        return {
            uuid: item?.uuid,
            business_uuid: transactionInfo.business_uuid,
            branch_uuid: transactionInfo.branch_uuid,
            party_uuid: transactionInfo.party_uuid,
            transaction_uuid: transactionInfo.uuid,
            transaction_type: transactionInfo.type,
            item_uuid: item.item_uuid,
            purchase_price: item.purchase_price,
            sales_price: item.sales_price,
            quantity: item.quantity,
            transaction_date: transactionInfo.transaction_date,
            item_wise_tax: item.item_wise_tax,
            total_tax: item.total_tax,
            _id: item['_id']
        }
    }
}
