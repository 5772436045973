
export class PartySettings {
    constructor() { }
    party_grouping: boolean = false;

    shipping_address_enabled: boolean = false;
    shipping_address_print_enabled: boolean = false;

    payment_reminder_enabled: boolean = false;
    payment_reminder_message: string = 'Hi,\nIt\'s a friendly reminder to you for paying <BALANCE AMOUNT> to me';
    payment_reminder_due_days: number = 1;

    additional_field_1_enabled: boolean = false;
    additional_field_1: string = null;
    additional_field_1_print_enabled: boolean = false;

    additional_field_2_enabled: boolean = false;
    additional_field_2: string = null;
    additional_field_2_print_enabled: boolean = false;

    additional_field_3_enabled: boolean = false;
    additional_field_3: string = null;
    additional_field_3_print_enabled: boolean = false;

    additional_field_4_enabled: boolean = false;
    additional_field_4: string = null;
    additional_field_4_date_format: string = 'dd/mm/yy';
    additional_field_4_print_enabled: boolean = false;
}
