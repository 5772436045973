import { Action } from '@ngrx/store';
import { SyncGatewayUser } from 'app/shared/models/sync-gateway-user.model';

export enum ESyncUserActions {
    SYNC_USER_LOAD = "SYNC_USER_LOAD",
    SYNC_USER_FETCH = "SYNC_USER_FETCH",
    SYNC_USER_FETCH_SUCCESS = "SYNC_USER_FETCH SUCCESS",
    SYNC_USER_FETCH_FAIL = "SYNC_USER_FETCH FAIL",
    SYNC_USER_CREATE = "SYNC_USER_CREATE",
    SYNC_USER_CREATE_SUCCESS = "SYNC_USER_CREATE SUCCESS",
    SYNC_USER_CREATE_FAIL = "SYNC_USER_CREATE FAIL"
}

export class SyncUserLoadAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_LOAD;
    constructor() { }
}

export class SyncUserFetchAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_FETCH;
    constructor() { }
}

export class SyncUserFetchSuccessAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_FETCH_SUCCESS;
    constructor(public payload: SyncGatewayUser) { }
}

export class SyncUserFetchFailAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_FETCH_FAIL;
    constructor(public payload: any) { }
}

export class SyncUserCreateAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_CREATE;
    constructor() { }
}

export class SyncUserCreateSuccessAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_CREATE_SUCCESS;
    constructor(public payload: SyncGatewayUser) { }
}

export class SyncUserCreateFailAction implements Action {
    readonly type = ESyncUserActions.SYNC_USER_CREATE_FAIL;
    constructor(public payload: any) { }
}

export type SyncUserActions = SyncUserLoadAction
    | SyncUserFetchAction
    | SyncUserFetchSuccessAction
    | SyncUserFetchFailAction
    | SyncUserCreateAction
    | SyncUserCreateSuccessAction
    | SyncUserCreateFailAction;
