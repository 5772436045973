import { Injectable } from "@angular/core";

import { combineLatest, of } from "rxjs";
import { catchError, concatMap, switchMap, withLatestFrom } from "rxjs/operators";

import { Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { IAppState } from "../app.state";
import { selectProducts, selectParties } from "../selectors/catalog.selectors";
import {
    ECatalogActions, LoadCatalogSuccessAction, UpdateCatalogProductSuccessAction,
    UpdateCatalogPartySuccessAction, AddCatalogPartyAction, UpdateCatalogPartyAction,
    AddCatalogProductAction, UpdateCatalogProductAction, DeleteCatalogProductAction,
    UpdateCatalogPartyByTransactionAction, UpdateCatalogProductByTransactionAction,
    UpdateCatalogProductByTransactionDeleteAction, UpdateCatalogProductByTransactionDeleteSuccessAction,
    UpdateCatalogPartyByTransactionDeleteAction, UpdateCatalogPartyByTransactionDeleteSuccessAction
} from "../actions/catalog.actions";

import { ToastrService } from "ngx-toastr";

import { PartyService } from "app/account/party/services";
import { ProductService } from "app/account/product/services/product/product.service";
import { StockReportService } from "app/account/reports/services/stock-report.service";

import { ETransactionType } from "app/account/transaction/enums";
import { Party, PartyBalance } from "app/account/party/models";
import { PrevTransaction, Transaction, UpdatedTransaction } from "app/account/transaction/models";
import { ProductStock } from "app/account/product/models/product.stock.model";

@Injectable()
export class CatalogEffects {

    constructor(
        private action$: Actions,
        private stockReportService: StockReportService,
        private toastr: ToastrService,
        private productService: ProductService,
        private partyService: PartyService,
        private store: Store<IAppState>,
    ) { }

    @Effect()
    loadCatalog$ = this.action$.pipe(
        ofType(ECatalogActions.CATALOG_LOAD),
        concatMap(() => {
            return combineLatest([this._getProducts(), this._getParties()]);
        }),
        switchMap((catalog) => {
            return this.stockReportService.loadCatalog(catalog).pipe(
                switchMap(([products, parties]: any[]) => {
                    return of(new LoadCatalogSuccessAction({ products, parties }));
                }),
                catchError((error) => {
                    this.toastr.error(error);
                    return of(null);
                })
            );
        })
    );

    @Effect()
    addProductStock$ = this.action$.pipe(
        ofType<AddCatalogProductAction>(ECatalogActions.CATALOG_PRODUCT_ADD),
        withLatestFrom(this.store.select(selectProducts)),
        concatMap(([action, products]) => {
            const payload: any = (<any>action).payload;

            return this._addProductStock(products, payload.item);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogProductSuccessAction(response ? response : null));
        })
    );

    @Effect()
    updateProductStock$ = this.action$.pipe(
        ofType<UpdateCatalogProductAction>(ECatalogActions.CATALOG_PRODUCT_UPDATE),
        withLatestFrom(this.store.select(selectProducts)),
        concatMap(([action, products]) => {
            const payload: any = (<any>action).payload;

            return this._updateProductStock(products, payload.item);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogProductSuccessAction(response ? response : null));
        })
    );

    @Effect()
    updateCatalogProductByTransaction$ = this.action$.pipe(
        ofType<UpdateCatalogProductByTransactionAction>(ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION),
        withLatestFrom(this.store.select(selectProducts)),
        concatMap(([action, products]) => {
            const payload: any = (<any>action).payload;
            const { updatedTransaction, prevTransaction } = payload;

            return this._updateProductStockByTransaction(products, prevTransaction, updatedTransaction);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogProductSuccessAction(response ? response : null));
        })
    );

    @Effect()
    UpdateCatalogProductByTransactionDeleteAction$ = this.action$.pipe(
        ofType<UpdateCatalogProductByTransactionDeleteAction>(ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE),
        withLatestFrom(this.store.select(selectProducts)),
        concatMap(([action, products]) => {
            const payload: any = (<any>action).payload;
            const { deletedTransaction } = payload;

            return this._updateProductStockByTransactionDelete(products, deletedTransaction);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogProductByTransactionDeleteSuccessAction(response ? response : null));
        })
    );

    @Effect()
    addCatalogParty$ = this.action$.pipe(
        ofType<AddCatalogPartyAction>(ECatalogActions.CATALOG_PARTY_ADD),
        withLatestFrom(this.store.select(selectParties)),
        concatMap(([action, parties]: [AddCatalogPartyAction, PartyBalance[]]) => {
            const newParty = action.party;
            parties.push(newParty);

            return of(parties);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogPartySuccessAction(response ? response : null));
        })
    );

    @Effect()
    updateCatalogParty$ = this.action$.pipe(
        ofType<UpdateCatalogPartyAction>(ECatalogActions.CATALOG_PARTY_UPDATE),
        withLatestFrom(this.store.select(selectParties)),
        concatMap(([action, parties]) => {
            const payload: any = (<any>action).payload;

            return this._updatePartyBalance(parties, payload.updatedParty);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogPartySuccessAction(response ? response : null));
        })
    );

    @Effect()
    updateCatalogPartyByTransaction$ = this.action$.pipe(
        ofType<UpdateCatalogPartyByTransactionAction>(ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION),
        withLatestFrom(this.store.select(selectParties)),
        concatMap(([action, parties]) => {
            const payload: any = (<any>action).payload;
            const { updatedTransaction, prevTransaction } = payload;

            return this._updatePartyBalanceByTransaction(parties, updatedTransaction, prevTransaction);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogPartySuccessAction(response ? response : null));
        })
    );

    @Effect()
    updateCatalogPartyByTransactionDelete$ = this.action$.pipe(
        ofType<UpdateCatalogPartyByTransactionDeleteAction>(ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE),
        withLatestFrom(this.store.select(selectParties)),
        concatMap(([action, parties]) => {
            const payload: any = (<any>action).payload;
            const { deletedTransaction } = payload;

            return this._updatePartyBalanceByTransactionDelete(parties, deletedTransaction);
        }),
        switchMap((response) => {
            return of(new UpdateCatalogPartyByTransactionDeleteSuccessAction(response ? response : null));
        })
    );

    private _addProductStock(products: ProductStock[], item: ProductStock) {
        products.push(item);

        return of(products);
    }

    private _updateProductStock(products: ProductStock[], item: ProductStock) {
        let index = products?.findIndex(stockItem => item.uuid === stockItem.uuid);
        item.quantity = products[index]?.quantity;
        products[index] = item;

        return of(products);
    }

    private _updateProductStockByTransaction(products: ProductStock[], prevTransaction: PrevTransaction, updatedTransaction: UpdatedTransaction) {
        let updatedTransactionItems: any[] = updatedTransaction?.items;
        let prevTransactionItems: any[] = prevTransaction?.items;

        if (updatedTransaction?.type == ETransactionType.SALES || updatedTransaction.type == ETransactionType.PURCHASE_RETURN) {
            for (let i = 0; i < updatedTransactionItems.length; i++) {
                let index = products.findIndex((product) => product.uuid == updatedTransactionItems[i].item_uuid);
                if (index > -1) {
                    products[index].quantity = products[index].quantity - updatedTransactionItems[i].quantity;
                }
            }

            for (let i = 0; i < prevTransactionItems?.length; i++) {
                let index = products.findIndex((product) => product.uuid == prevTransactionItems[i].item_uuid);
                products[index].quantity = products[index].quantity + prevTransactionItems[i].quantity;
            }
        }

        if (updatedTransaction?.type == ETransactionType.SALES_RETURN || updatedTransaction?.type == ETransactionType.PURCHASE) {
            for (let i = 0; i < updatedTransactionItems.length; i++) {
                let index = products.findIndex((product) => product.uuid == updatedTransactionItems[i].item_uuid);
                products[index].quantity = products[index].quantity + updatedTransactionItems[i].quantity;
            }

            for (let i = 0; i < prevTransactionItems?.length; i++) {
                let index = products.findIndex((product) => product.uuid == prevTransactionItems[i].item_uuid);
                products[index].quantity = products[index].quantity - prevTransactionItems[i].quantity;
            }
        }

        return of(products);
    }

    private _updateProductStockByTransactionDelete(products: ProductStock[], transaction: UpdatedTransaction) {
        let deletedTransactionItems: any[] = transaction?.items;

        if (transaction?.type == ETransactionType.SALES || transaction.type == ETransactionType.PURCHASE_RETURN) {
            for (let i = 0; i < deletedTransactionItems.length; i++) {
                let index = products.findIndex((product) => product.uuid == deletedTransactionItems[i].item_uuid);
                if (index > -1) {
                    products[index].quantity = products[index].quantity + deletedTransactionItems[i].quantity;
                }
            }
        }

        if (transaction?.type == ETransactionType.SALES_RETURN || transaction?.type == ETransactionType.PURCHASE) {
            for (let i = 0; i < deletedTransactionItems.length; i++) {
                let index = products.findIndex((product) => product.uuid == deletedTransactionItems[i].item_uuid);
                if (index > -1) {
                    products[index].quantity = products[index].quantity - deletedTransactionItems[i].quantity;
                }
            }
        }

        return of(products);
    }

    private _updatePartyBalance(parties: PartyBalance[], updatedParty: Party) {
        let index = parties.findIndex(p => p.uuid === updatedParty.uuid);
        const party = parties[index];
        if (index > -1) {
            party.balance = (party.balance - party.current_balance + updatedParty.current_balance);
        }
        return of(parties);
    }

    private _calculateBalance(transaction: Transaction, prevTransaction: PrevTransaction, party: any) {
        let totalBalance = party.balance;

        if (transaction.type === ETransactionType.SALES || transaction.type === ETransactionType.PURCHASE_RETURN || transaction.type === ETransactionType.PAYMENT_IN) {
            totalBalance = totalBalance - prevTransaction?.due_amount + transaction?.due_amount;
        } else if (transaction.type === ETransactionType.PURCHASE || transaction.type === ETransactionType.SALES_RETURN || transaction.type === ETransactionType.PAYMENT_OUT) {
            totalBalance = totalBalance + prevTransaction?.due_amount - transaction?.due_amount;
        }

        return totalBalance;
    }

    private _calculateBalanceByTransactionDelete(transaction: Transaction, party: any) {
        let totalBalance = party.balance;

        if (transaction.type === ETransactionType.SALES || transaction.type === ETransactionType.PURCHASE_RETURN || transaction.type === ETransactionType.PAYMENT_IN) {
            totalBalance = totalBalance + transaction?.due_amount;
        } else if (transaction.type === ETransactionType.PURCHASE || transaction.type === ETransactionType.SALES_RETURN || transaction.type === ETransactionType.PAYMENT_OUT) {
            totalBalance = totalBalance - transaction?.due_amount;
        }

        return totalBalance;
    }

    private _updatePartyBalanceByTransaction(parties, prevTransaction: PrevTransaction, updatedTransaction: UpdatedTransaction) {
        let index = parties.findIndex(p => p.uuid === updatedTransaction?.party_uuid);
        const party = parties[index];

        if (index > -1) {
            party.balance = this._calculateBalance(updatedTransaction, prevTransaction, party);
        }

        return of(parties);
    }

    private _updatePartyBalanceByTransactionDelete(parties, deletedTransaction: Transaction) {
        let index = parties.findIndex(p => p.uuid === deletedTransaction?.party_uuid);
        const party = parties[index];

        if (index > -1) {
            party.balance = this._calculateBalanceByTransactionDelete(deletedTransaction, party);
        }

        return of(parties);
    }

    private _getProducts() {
        return this.productService.getProducts();
    }

    private _getParties() {
        return this.partyService.getParties();
    }
}
