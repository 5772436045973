import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ETransactionType } from 'app/account/transaction/enums';
import { Product } from 'app/account/product/models/product.model';
import { Party } from 'app/account/party/models/party.model';
import { PartyBalance } from 'app/account/party/models/party-balance.model';
import { ProductStock } from 'app/account/product/models/product.stock.model';
import { Transaction, TransactionItem } from 'app/account/transaction/models';

import { ReportService } from './report.service';
import { TransactionService, TransactionItemService } from 'app/account/transaction/services';

@Injectable({
  providedIn: 'root'
})
export class StockReportService {
  constructor(
    private reportService: ReportService,
    private transactionService: TransactionService,
    private transactionItemService: TransactionItemService,
  ) { }

  public getStockSummary(params: any): Observable<ProductStock[]> {
    let { start_date, end_date } = params;
    if (!params?.start_date && !params?.end_date) {
      const date = new Date();
      const year = date.getFullYear(), month = date.getMonth(), day = date.getDate();
      start_date = new Date(0, 0, 0, 0, 0, 1);
      end_date = new Date(year, month, day, 23, 59, 59);
    }
    let findConditions = {
      ...this.reportService.buildDateConditions(start_date, end_date)
    };

    return this.transactionItemService.getTransactionItems(findConditions).pipe(
      map((transaction_items: any[]) => {
        return this._calculateStock(transaction_items, params?.items);
      }),
      // TODO - later
      // Implement end_date logic
    );
  }

  public getAllProductsStock(products: Product[]): Observable<ProductStock[]> {
    const findConditions = {};
    return this.transactionItemService.getTransactionItems(findConditions).pipe(
      map((transaction_items: any[]) => {
        return this._calculateStock(transaction_items, products);
      }),
      // map((stock_products: ProductStock[]) => {
      //   return this._calculateWithInitialStock(stock_products, products, true);
      // })
    );
  }

  public getAllPartiesBalance(parties: Party[]): Observable<PartyBalance[]> {
    // const findConditions = {
    //   $or: [
    //     { type: ETransactionType.PURCHASE },
    //     { type: ETransactionType.PURCHASE_RETURN },
    //     { type: ETransactionType.SALES },
    //     { type: ETransactionType.SALES_RETURN },
    //   ]
    // };
    const findConditions = { transaction_date: { $gt: new Date(0, 0, 0, 0, 0, 1).getTime() } };

    return this.transactionService.getTransactions(findConditions).pipe(
      map((transactions: any[]) => {
        return this._calculatePartyBalance(transactions, parties);
      })
    );
  }

  public loadCatalog([products, parties]: any[]) {
    return combineLatest([this.getAllProductsStock(products), this.getAllPartiesBalance(parties)]);
  }

  public getItemDetailsStock(params: any) {
    const { start_date, end_date, item } = params;

    const findConditions = {
      item_uuid: item.uuid,
      ...this.reportService.buildDateConditions(start_date, end_date),
    };

    return this.transactionItemService.getTransactionItems(findConditions).pipe(
      map((transaction_items: []) => {
        return this._calculateDateWiseItemStock(start_date, end_date, transaction_items, item);
      })
    );
  }

  private _calculateStock(transaction_items: TransactionItem[], products: Product[]): ProductStock[] {
    // const productsMap = products.reduce((obj, prod) => (obj[prod.uuid] = prod, obj), {});

    const groupByItemUuid = Object.entries(transaction_items.reduce((r, c) => (r[c.item_uuid] = [...r[c.item_uuid] || [], c], r), {}));
    let itemTransactions = groupByItemUuid.reduce((r, c) => (r.push({ item_uuid: c[0], items: c[1] }), r), []);
    itemTransactions = itemTransactions.filter(group => { return group.item_uuid && group.item_uuid !== 'undefined' });

    let stockProducts: ProductStock[] = [];

    stockProducts = products.map(product => {
      let stockItem = new ProductStock();
      const transactionMap = itemTransactions.find(p => p.item_uuid == product.uuid);
      let quantity = product.stock_count;
      if (transactionMap) {
        quantity = this._calculateQuantity(transactionMap.items, product);
      }
      stockItem = { ...product, quantity: quantity };
      return stockItem;
    });

    // TODO - later
    // if (returnAll) {
    //   stockProducts.push(stockItem);
    // } else if (!returnAll && element.stock_count > 0 && element.created_at <= filter_date?.getTime()) {
    //   stockProducts.push(stockItem);
    // }

    return stockProducts;
  }

  private _calculatePartyBalance(transactions: Transaction[], parties: Party[]): PartyBalance[] {
    const groupByPartyUuid = Object.entries(transactions.reduce((r, c) => (r[c.party_uuid] = [...r[c.party_uuid] || [], c], r), {}));
    let partyTransactions = groupByPartyUuid.reduce((r, c) => (r.push({ party_uuid: c[0], items: c[1] }), r), []);
    partyTransactions = partyTransactions.filter(group => { return group.party_uuid && group.party_uuid !== 'undefined' });

    let partyBalances: PartyBalance[] = [];

    partyBalances = parties.map(party => {
      let balanceItem = new PartyBalance();
      const transactionMap = partyTransactions.find(p => p.party_uuid == party.uuid);
      let balance = party.current_balance;
      if (transactionMap) {
        balance = this._calculateBalance(transactionMap.items, party);
      }
      balanceItem = { ...party, balance: balance }
      return balanceItem;
    });

    return partyBalances;
  }

  private _calculateQuantity(items: TransactionItem[], product: Product) {
    return items.reduce((count, item) => {
      if (item.transaction_type === ETransactionType.SALES || item.transaction_type === ETransactionType.PURCHASE_RETURN) {
        return count - item.quantity;
      } else if (item.transaction_type === ETransactionType.PURCHASE || item.transaction_type === ETransactionType.SALES_RETURN) {
        return count + item.quantity;
      }
    }, product.stock_count || 0);
  }

  private _calculateBalance(transactions: Transaction[], party: Party) {
    return transactions.reduce((amount, transaction) => {
      if (transaction.type === ETransactionType.SALES || transaction.type === ETransactionType.PURCHASE_RETURN || transaction.type === ETransactionType.PAYMENT_IN) {
        return amount - transaction.due_amount;
      } else if (transaction.type === ETransactionType.PURCHASE || transaction.type === ETransactionType.SALES_RETURN || transaction.type === ETransactionType.PAYMENT_OUT) {
        return amount + transaction.due_amount;
      }
    }, (party.current_balance || 0));
  }

  private _calculateDateWiseItemStock(startDate: number, endDate: number, transaction_items: [] = [], item) {
    const dateStart = new Date(startDate);
    const dateEnd = new Date(endDate);
    // TODO - calculate closing quantity based on stock of that date
    let closing_quantity = 0; // item.stock_count;

    const dateWiseItems = [];

    for (const d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
      transaction_items.forEach((element: any) => {
        if (element.transaction_date >= d.getTime() && element.transaction_date <= (d.getTime() + 86398000)) {
            let sales_quantity = 0;
            let purchase_quantity = 0;
            if (element.transaction_type === ETransactionType.SALES) {
                sales_quantity += element.quantity;
            } else if (element.transaction_type === ETransactionType.SALES_RETURN) {
                sales_quantity -= element.quantity;
            } else if (element.transaction_type === ETransactionType.PURCHASE) {
                purchase_quantity += element.quantity;
            } else if (element.transaction_type === ETransactionType.PURCHASE_RETURN) {
                purchase_quantity -= element.quantity;
            }
            closing_quantity = (closing_quantity + purchase_quantity - sales_quantity);
            dateWiseItems.push({ transaction_date: d.getTime(), sales_quantity: sales_quantity, purchase_quantity: purchase_quantity, closing_quantity: closing_quantity });
        }
      });

      // Check If datewise item already pushed to array or not.
      if (!dateWiseItems.some(item => item.transaction_date === d.getTime())) {
        dateWiseItems.push({ transaction_date: d.getTime(), sales_quantity: 0, purchase_quantity: 0, closing_quantity: closing_quantity });
      }
    }
    return dateWiseItems;
  }
}
