import { Injectable, Injector } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    EPermissionActions,
    PermissionSetSuccessAction,
} from '../actions/permission.actions';

import { BaseService } from 'app/shared/services/base.service';

@Injectable()
export class PermissionEffects extends BaseService {
    constructor(
        injector: Injector,
        private action$: Actions) {
        super(injector);
    }

    @Effect()
    setPermission$ = this.action$.pipe(
        ofType(EPermissionActions.PERMISSION_SET),
        switchMap((response) => {
            return of(new PermissionSetSuccessAction());
        })
    );
}
