import { EAccountActions, AccountsActions } from '../actions/accounts.actions';

export const initialState = {
    //set initial state
    accountInfo: null,
    errorMessage: null
};
export function accountReducer(state = initialState, action: AccountsActions) {
    switch (action.type) {
        case EAccountActions.ACCOUNT_INFO_FETCH: {
            return { ...state };
        }
        case EAccountActions.ACCOUNT_INFO_GET_SUCCESS: {
            return {
                ...state,
                accountInfo: action.payload,
                errorMessage: null
            };
        }
        case EAccountActions.ACCOUNT_INFO_GET_FAIL: {
            return {
                ...state,
                accountInfo: null,
                errorMessage: "No account Info found"
            };
        }

        default:
            return state;
    }
}