import { Injector } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IAppState } from 'app/store/app.state';

import { selectCurrentPermissions, selectCurrentRole } from 'app/store/selectors/permission.selectors';

import { Roles } from 'app/account/utils/permission/role-permission.config';


export class BasePermission {
    private currentPermission$: Observable<any>;
    private currentRole$: Observable<any>;
    public permissions;
    protected currentRole = Roles.VIEWER;

    public get isViewer() { return this.currentRole === Roles.VIEWER };

    constructor(protected injector: Injector, protected store: Store<IAppState>) {
        this.currentPermission$ = this.store.select(selectCurrentPermissions);
        this.currentPermission$.subscribe((permissions: any) => {
            this.permissions = permissions;
        });

        this.currentRole$ = this.store.select(selectCurrentRole);
        this.currentRole$.subscribe((role: any) => {
            this.currentRole = role;
        });
    }
}
