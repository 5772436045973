// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  baseApi: '',
  // apiPath: '/api/v1/merchant/',
  // apiPath: 'https://api.ledger-manager.com/api/v1/merchant/',
  apiPath: 'https://test.ledger-manager.com/api/v1/merchant/',
  syncUserApiPath: 'https://test-syncuser.ledger-manager.com/api/v1/',
  imgPath: '/storage/',

  GOOGLE_MAP_API_KEY: 'AIzaSyD022FhP0v0x7Tx6bgrO5e8Yo_Xo4kU1Iw',

  localDB: 'lmdbstage',
  sync_gateway: {
    protocol: 'https',
    url: 'test-sync.ledger-manager.com/lmbucket',
    private_key:
      '-----BEGIN RSA PRIVATE KEY-----' +
      'MIIEpAIBAAKCAQEAqDoHcopPmGy3E595F/pQbiMUL5re0L1v8FP5RVitx2gbO7Ci' +
      'E0C+vO/ZJoz4stHIOV919MrPH5PTW5dwm4F/WyitDB0UX4zIjML6y+UhWeWVS1UX' +
      'NGLK0kwlA/ZH1YL4G46qUOh9iOM4hi82TYSnQVBly7+lV/pt6i1tPneS+PJ7zAKl' +
      'vgqdggl+HrvocQ45jbBmYP1k99P5bWX7oe1VRLk5ja5UODNxIlG1/FJmb+1qyU1/' +
      '0sbsygzbiAKeDCeMUXSKRtJxo+5YihR9ITFFQS3CGp6nTAdPbzEQjZa78kEF98gH' +
      'CtnReiSj1DQSQmgovo1eITz+3YM2cGtUL9+iLwIDAQABAoIBABdMEgP2y5ccVy5M' +
      'g4Sikcju6ri9BeZ6lan+tyK3aTmYXU0i5Q8thDy+KqCAHWEGVGg9pCRGgfCplDe2' +
      '/U1L3ctOEsj+rfedk5iK2BisnrXIaaiJHDGRDO2axfWKgeA7xwhp9YRG7juq3AUZ' +
      'PLf3iwIslCxnWgqZD+fCasKf5Ka9E/1Rd0pl6eZgcFAwwDf/axtDs63JsicBqXnz' +
      'HAHPWNV3qR86v8ct+Py2gk6ljLRlY/VdGS+pWT0SeF8BssZBj3+klgNfEb4qbP9V' +
      'RwHF1P0eGbHSqA+WLXuG1JU6Pb1dQPqkB8476/TVjz0/XtJ0UFAylboyz6DsGhuT' +
      'OBkEweECgYEA747j+c40EwpkKJdjAapLsigUYe+QJpaiNAypXV8+0BO1ML7RFEv7' +
      'dIlmsq9ufe6iKLjYwXhsqGGVFLMEZv9enjEbleyjZ7/5N1yqUZIf8balKfdktS2c' +
      'OhZLCOxSe1AONLSYf8kktxjmCiTWbxAj9QHNQ7oIEaaqBGhtjEGXaXsCgYEAs8XS' +
      'nEINtDRWDhe7uYMwG3XjzcsyWca9/7go4AL0K+yEWzOe4qzC+4GT06fUztbBmf14' +
      'u7yNPR52frH1ei6feVGJbCx1hdmvikfdTGrwpFp+7MIv2ek27ZWz0c+O2+RXpSac' +
      'yWvMgkU4nlgw9PFqmO1L1syCKQTf4SUQEV+Iyd0CgYEAlgoiAXjEJtTQTJgcu5NE' +
      'm838jiZ0W6bm6sCvZzrbZYmiZ2hsHVFFqgTQ3MOB6cAHsoerwulA7OlJbVB+RmBV' +
      'i7DoPSucYMPdPG5+gl6KQWMK6VD4s31gpwUFdA5HztIiQ6KFDSwXIdKnad0OrTsn' +
      'ujGptWyT1zJStDYPvtPqGHcCgYBhyxRV4mzYAbxqcCizsxTBa3Ef6xCZ285RxJmf' +
      'upufJUue3UbqYjazYHrNIbhXSGYK4eMCpYBjpiWpqB80dxZThbJvAQRxasjePVe6' +
      'EVaU4ZG4M66aKQ6zUcN/OByrq9ytv4J848Jdf1Bcevcu2AE37aZa0+debwNm06WY' +
      '/dJIlQKBgQCR+WLYXIkoCqcxFBIov518CR6xUPihtKAsnXA1WYvptOEJ2zVFNXAi' +
      'A9aFuhbVijWR/pCXm1FSPOiPJHgKSjtAtuRIqk/DRm2LSFssQlPPSFqIBdd8Bb+4' +
      'CA3teX+5shHjsKf5RG+WNw7p/MPiRDcMZOsOiSkLblV+pcRJ05EwUw==' +
      '-----END RSA PRIVATE KEY-----'
  },

  AWS: {
    BUCKET_NAME: "ledger-manager-test",
    ACCESS_KEY: "AKIA3QFNCO4HBZ4THMMA",
    SECRET_KEY: "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",
    REGION: "ap-southeast-1",
  },

  DateFormat: 'DD-MM-YYYY',
  DateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
};
