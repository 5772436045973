import { Injectable, OnDestroy } from '@angular/core';

import * as rawPouchDB from 'pouchdb';
import * as rawPouchFind from 'pouchdb-find';
import * as rawPouchUpsert from 'pouchdb-upsert';
import * as rawPouchAuth from 'pouchdb-authentication';
import * as rawPouchQuickSearch from 'pouchdb-quick-search';

import { environment } from 'environments/environment';

const PouchDB: PouchDB.Static = (rawPouchDB as any).default;
const PouchFind: PouchDB.Plugin = (rawPouchFind as any).default;
const PouchUpsert: PouchDB.Plugin = (rawPouchUpsert as any).default;
const PouchAuth: PouchDB.Plugin = (rawPouchAuth as any).default;
const PouchQuickSearch: PouchDB.Plugin = (rawPouchQuickSearch as any).default;

PouchDB.plugin(PouchFind);
PouchDB.plugin(PouchUpsert);

// TODO - investigate later, currently not required
PouchDB.plugin(PouchAuth);

PouchDB.plugin(PouchQuickSearch);

@Injectable({
  providedIn: 'root'
})
export class PouchdbService implements OnDestroy {
  private isDbInitiated: boolean;
  private database: PouchDB.Database;

  public constructor() {
    if (!this.isDbInitiated) {
      this.database = new PouchDB(environment.localDB);
      this.database.setMaxListeners(20);
      this.isDbInitiated = true;

      this.createIndices().then(result => {
        // console.log('indices created');
      });
    }
  }

  public get localDatabase(): PouchDB.Database {
    return this.database;
  }

  private createIndices() {
    const businessTableIndexOpts: PouchDB.Find.CreateIndexOptions = {
      index: {
        fields: ['business_uuid', 'table_type']
      }
    }
    const transactionTypeIndexOpts: PouchDB.Find.CreateIndexOptions = {
      index: {
        fields: ['business_uuid', 'table_type', 'type'] // transaction type
      }
    }
    const transactionDateIndexOpts: PouchDB.Find.CreateIndexOptions = {
      index: {
        fields: ['transaction_date'] // transaction date
      }
    }
    const createdAtIndexOpts: PouchDB.Find.CreateIndexOptions = {
      index: {
        fields: ['created_at'] // settings create date
      }
    }
    const expenseDateIndexOpts: PouchDB.Find.CreateIndexOptions = {
      index: {
        fields: ['expense_date'] // expense item date
      }
    }

    return this.database.createIndex(businessTableIndexOpts)
      .then(() => {
        return this.database.createIndex(transactionTypeIndexOpts)
      })
      .then(() => {
        return this.database.createIndex(transactionDateIndexOpts)
      })
      .then(() => {
        return this.database.createIndex(createdAtIndexOpts)
      })
      .then(() => {
        return this.database.createIndex(expenseDateIndexOpts)
      });
  }

  public fetchAll(option) {
    return this.database.allDocs({ ...option, include_docs: true, deleted: false });
  }

  public list(fields: string[], selector: any) {
    fields = ['_id', '_rev', ...fields];
    return this.database.find({
      fields: fields,
      selector: selector,
      limit: 20,
    })
  }

  public get(id: string) {
    return this.database.get(id);
  }

  public put(id: string, document: any) {
    document._id = id;
    return this.get(id).then(result => {
      document._rev = result._rev;
      document = { ...result, ...document };
      return this.database.put(document).then(doc => {
        return this.get(document._id);
      })
    }, error => {
      // console.log('error: ', error);
      if (error.status == "404") {
        return this.database.put(document).then(doc => {
          return this.get(document._id);
        })
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    });
  }

  public remove(doc: any) {
    return this.database.remove(doc._id, doc._rev);
  }

  public search(option) {
    return this.database.search(option);
  }

  public replicate() { }

  // Needed for Map/Reduce queries
  public query() {
  }

  public find(selector: PouchDB.Find.Selector, fields?: any, sort?: any, limit?: number, skip?: number): Promise<any> {
    return this.database.find({
      selector: selector,
      fields: fields,
      sort: sort,
      limit: limit,
      skip: skip
    });
  }

  ngOnDestroy() {
    this.database.removeAllListeners();
    this.database.destroy();
  }
}
