import { Injector } from '@angular/core';

import { from } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/app.state';

import { BasePermission } from './base-permission';
import { PouchdbService } from 'app/services/pouchdb.service';


export class BasePouchDbList extends BasePermission {
  protected dbService: PouchdbService;

  protected fields: string[] = [];
  protected query: string = '';
  protected filter: (doc: any) => {} = null;

  protected options = {
    query: this.query,
    fields: this.fields,
    filter: this.filter,
    include_docs: true,
  };

  public totalItems: number = 0;
  public results: any[] = [];

  constructor(protected injector: Injector, protected store: Store<IAppState>) {
    super(injector, store);
    this.dbService = this.injector.get(PouchdbService);
  }

  protected setOptions() {
    this.options = {
      query: this.query,
      fields: this.fields,
      filter: this.filter,
      include_docs: true,
    };
  }

  protected getData() {
    this.setOptions();
    const result$ = from(this.dbService.search(this.options));
    return result$;
  }

  protected mapData() {
    return this.getData().pipe(
      tap(res => {
        this.totalItems = res.total_rows;
      }),
      map(data => data.rows.map((row: any) => {
        return {
          ...row.doc,
        };
      }))
    );
  }
}
