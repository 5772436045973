import { ECatalogActions, CatalogActions } from '../actions/catalog.actions';
import { CatalogState } from '../app.state';

export const initialState: CatalogState = {
    // set initial state
    products: [],
    parties: [],
};

export function catalogReducer(state = initialState, action: CatalogActions) {
    switch (action.type) {

        case ECatalogActions.CATALOG_LOAD: {
            return { ...state };
        }

        case ECatalogActions.CATALOG_LOAD_SUCCESS: {
            return {
                ...state,
                products: action.catalog.products,
                parties: action.catalog.parties,
            };
        }

        case ECatalogActions.CATALOG_PRODUCT_UPDATE: {
            return { ...state, params: action.payload };
        }

        case ECatalogActions.CATALOG_PRODUCT_UPDATE_SUCCESS: {
            return {
                ...state,
                products: action.payload,
            };
        }

        case ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE: {
            return { ...state, params: action.payload };
        }

        case ECatalogActions.CATALOG_PARTY_UPDATE_BY_TRANSACTION_DELETE_SUCCESS: {
            return {
                ...state,
                parties: action.payload,
            };
        }

        case ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE: {
            return { ...state, params: action.payload };
        }

        case ECatalogActions.CATALOG_PRODUCT_UPDATE_BY_TRANSACTION_DELETE_SUCCESS: {
            return {
                ...state,
                products: action.payload,
            };
        }

        case ECatalogActions.CATALOG_PARTY_UPDATE: {
            return { ...state, params: action.payload };
        }

        case ECatalogActions.CATALOG_PARTY_UPDATE_SUCCESS: {
            return {
                ...state,
                parties: action.payload,
            };
        }
        default:
            return state;
    }
}
